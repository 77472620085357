import React from 'react'
import Pessoa1 from '../assets/imgs/pessoa-1.png'
import Pessoa2 from '../assets/imgs/pessoa-2.png'
import Pessoa3 from '../assets/imgs/pessoa-3.png'
import Aviso from '../assets/imgs/ico-8.png'

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <h2 className="title">
          Quem pode contar com
          <br /> <strong>o GeldCred Feliz</strong>
        </h2>

        <div className="micInd rowCard">
          <div className="imagem">
            <img src={Pessoa1} alt="Mulher sorrindo" />
          </div>
          <div className="esquerda">
            <div className="texto">
              <h3>
                <strong>
                  Microempreendedor
                  <br />
                  Individual
                </strong>
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 81 mil
              </p>
              {/* <p>
                <strong>Limite de crédito:</strong>
                <br />
                R$ 15 mil em 36 parcelas (sem carência) de até R$ 555,00
                <br />
                R$ 15 mil em 34 parcelas (02 meses de carência) de até R$ 590,00
                <br />
                *A taxa da garantia RSGaranti é de até
                <br />
                R$ 993,60
              </p> */}
              <p></p>
            </div>
          </div>
        </div>

        <div className="micEmp rowCard">
          <div className="imagem">
            <img
              src={Pessoa2}
              alt="Homem e mulher sorrindo. Ambos olhando um smartphone."
            />
          </div>
          <div className="direita">
            <div className="texto">
              <h3>
                <strong>Microempresa</strong>
              </h3>
              <p>
                com receita bruta anual igual <br /> ou inferior a R$ 360 mil
              </p>
              {/* <p>
                <strong>Limite de crédito:</strong>
                <br />
                <br />
                R$ 30 mil em 36 parcelas (sem carência) de até R$ 1.110,00
                <br />
                R$ 30 mil em 34 parcelas (02 meses de carência) de até R$
                1.170,00
                <br />
                *A taxa da garantia RSGaranti é de até <br />
                R$ 1.987,20
              </p> */}
            </div>
          </div>
        </div>

        <div className="peqEmp rowCard">
          <div className="imagem">
            <img
              src={Pessoa3}
              alt="Homem e mulher sorrindo. Mulher com o braço sobre o ombro do homem."
            />
          </div>
          <div className="esquerda">
            <div className="texto">
              <h3>
                <strong>Pequena Empresa</strong>
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 4,8
                milhões
              </p>
              {/* <p>
                <strong>Limite de crédito:</strong>
                <br />
                <br />
                R$ 50 mil em 36 parcelas (sem carência) de até R$ 1.840,00
                <br />
                R$ 50 mil em 34 parcelas (02 meses de carência) de até R$
                1.950,00
                <br />
                * A taxa da garantia RSGaranti é de até
                <br />
                R$ 3.312,00
              </p> */}
            </div>
          </div>
        </div>
        <div className="contAviso">
          <div className="aviso">
            <div className="imgAviso">
              <img
                src={Aviso}
                alt="Icone, formato Balão, com Exclamação dentro"
              />
            </div>

            <div className="textoAviso">
              <p>
                • Crédito sujeito a análise de crédito. Parcelas simuladas sem
                IOF.
                <br />• Terão acesso à linha associados que apresentarem no
                mínimo 01 ano de faturamento completo, declarados nos documentos
                indicados na documentação necessária abaixo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionE
