import React from 'react'

const BcxSectionF = () => {
  return (
    <section id="sectionF">
      <div className="containerF">
        <div className="title">
          <h2>
            <strong>Documentação</strong>
            <br />
            necessária
          </h2>
        </div>

        <div className="texto">
          <p>
            • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
            casadas ou com união estável devem apresentar também documentação do
            cônjuge. Cópias devem ser coloridas.
          </p>

          <div className="inscMunic">
            <p>• Certidão Negativa de Tributos Municipais</p>
            <a
              href={
                'https://pmfeliz.govbr.cloud:10443/cidadao/servlet/br.com.cetil.ar.jvlle.hatendimento'
              }
              target={'_blank'}
              rel="noreferrer"
            >
              ACESSE AQUI
            </a>
          </div>
          <br />
          <p>
            <strong>Mais documentos abaixo, conforme tipo de empresa:</strong>
          </p>
          <p className="tipoEmpresa">
            • Se empresa enquadrada como MEI:
            <ul>
              <li>
                - CCMEI: Certificado de Condição de Microempreendedor
                Individual.
              </li>
              <li>- DASN SIMEI: Declaração Anual do SIMEI.</li>
            </ul>
          </p>
          <p className="tipoEmpresa">
            • Se empresa enquadrada como ME / EPP:
            <ul>
              <li>- Declaração de Firma Individual ou Contrato Social</li>
              <li>
                - Empresa do Simples Nacional: <br />
                PGDAS-D. Programa Gerador do Documento de Arrecadação do Simples
                Nacional Declaratório 2022, com faturamento dos últimos 12
                meses.
              </li>
              <li>
                - Empresa do Lucro Presumido: <br />
                SPED Contábil | Recibo, Balanço e DRE
              </li>
            </ul>
          </p>
          <div className="asterisco">
            <p>
              * A documentação completa será solicitada somente após análise das
              informações fornecidas no formulário. Preencha com seus dados e
              aguarde contato do Sicredi.
            </p>
            <p>
              * Se você não é associado(a) da Sicredi Pioneira, será necessária
              a abertura de uma conta corrente.
            </p>
          </div>
        </div>
        <div className="imagem">
          <span />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionF
