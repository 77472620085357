import React from 'react'
import logoGeldCredFeliz from '../assets/imgs/logo.png'

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="slogan">
          <div className="conteudoAcxHeader">
            <img src={logoGeldCredFeliz} alt="Logotipo da GeldCredFeliz" />
            <h1>
              Solução em Microcrédito
              <br />
              <span>para a sua empresa.</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AcxHeader
